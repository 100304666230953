import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import RootLayout from '../../components/layouts/RootLayout'
import Dashboard from '../../components/Dashboard'
import Project from '../../pages/Project'
import ProjectLayout from '../../pages/Project/ProjectLayout'
import Login from '../../pages/Auth/Login'
import FindPw from '../../pages/Auth/FindPw'
import ChangePw from '../../pages/Auth/ChangePw'
import SignUp from '../../pages/Auth/SignUp'
import SignUpConfirm from '../../pages/Auth/SignUpConfirm'
import ProjectList from '../../pages/Dashboard/ProjectList'
import ExportList from '../../pages/Dashboard/ExportList'
import WorkspaceConfig from '../../pages/Dashboard/WorkspaceConfig'
import ProfileConfig from '../../pages/Dashboard/ProfileConfig'
import Subscription from '../../pages/Dashboard/Subscription'
import Home from '../../pages/Dashboard/Home'
import OAuth2Redirect from '../../pages/Auth/OAuth2Redirect'
import UserInfoUpdate from '../../pages/Auth/UserInfoUpdate'
import Checkout from '../../components/SubscriptionPlan/Checkout'
import PlanAction from '../../components/SubscriptionPlan/PlanAction'
import useAuth from '../../hooks/useAuth'
import External from '../../pages/External'
import ExternalMainPage from '../../pages/External/ExternalMainPage'
import InternationalizationPage from '../../pages/External/InternationalizationPage'
import AvatarListPage from '../../pages/External/AvatarListPage'
import RequiredAuth from './RequiredAuth'
import GlobalAvatarPage from '../../pages/External/GlobalAvatarPage'
import AIMascotPage from '../../pages/External/AIMascotPage'

function ProjectPreset() {
  const { user } = useAuth()

  return <Navigate to={`/workspace/${user?.workspaceList[0]?.id}`} replace />
}

export const routes = [
  {
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: (
          <RequiredAuth>
            <Dashboard />
          </RequiredAuth>
        ),
        children: [
          {
            path: '/workspace/:workspaceId',
            element: <Home />,
          },
          {
            path: '/workspace/:workspaceId/project',
            element: <ProjectList />,
          },
          {
            path: '/workspace/:workspaceId/export',
            element: <ExportList />,
          },
          {
            path: '/workspace/:workspaceId/config',
            element: <WorkspaceConfig />,
          },
          {
            path: '/workspace/:workspaceId/profile',
            element: <ProfileConfig />,
          },
          {
            path: '/workspace/:workspaceId/subscription',
            element: <Subscription />,
          },
          {
            path: '/workspace/:workspaceId/subscription/checkout',
            element: <Checkout />,
          },
          {
            path: '/workspace/:workspaceId/subscription/checkout/complete',
            element: <PlanAction type="change" />,
          },
          {
            path: '/workspace/:workspaceId/subscription/cancelled',
            element: <PlanAction type="cancel" />,
          },
          {
            path: '/workspace/:workspaceId/subscription/continue',
            element: <PlanAction type="continue" />,
          },
        ],
      },
      {
        element: (
          <RequiredAuth>
            <ProjectLayout />
          </RequiredAuth>
        ),
        children: [
          {
            path: '/workspace/:workspaceId/project/:projectId',
            element: <Project />,
          },
        ],
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/sign-up',
        element: <SignUp />,
      },
      {
        path: '/sign-up-confirm',
        element: <SignUpConfirm />,
      },
      {
        path: '/user-info-update',
        element: <UserInfoUpdate />,
      },
      {
        path: '/find-pw',
        element: <FindPw />,
      },
      {
        path: '/change-pw',
        element: <ChangePw />,
      },
      {
        path: '/oauth2/redirect',
        element: <OAuth2Redirect />,
      },
      {
        path: '/api/preset-project',
        element: <ProjectPreset />,
      },
    ],
  },
  {
    children: [
      {
        path: '/external',
        element: <External />,
        children: [
          { path: '', element: <ExternalMainPage /> },
          { path: 'internationalization', element: <InternationalizationPage /> },
          { path: 'global-avatar', element: <GlobalAvatarPage /> },
          { path: 'ai-mascot', element: <AIMascotPage /> },
          { path: 'avatar-list', element: <AvatarListPage /> },
        ],
      },
    ],
  },

  // {
  //   path: "*",
  //   element: <NotFound />
  // }
]

function AivatarRouterProvider() {
  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} />
}

export default AivatarRouterProvider
