import global_ko from './videos/global_ko.mp4'
import global_en from './videos/global_en.mp4'
import global_es from './videos/global_es.mp4'
import global_fr from './videos/global_fr.mp4'

const data = [
  {
    label: { ko: '한국어', en: 'Korean' },
    url: {
      ko: global_ko,
      en: global_ko,
    },
  },
  {
    label: { ko: '영어', en: 'English' },
    url: {
      ko: global_en,
      en: global_en,
    },
  },
  {
    label: { ko: '스페인어', en: 'Spanish' },
    url: {
      ko: global_es,
      en: global_es,
    },
  },
  {
    label: { ko: '프랑스어', en: 'French' },
    url: {
      ko: global_fr,
      en: global_fr,
    },
  },
]

export default data
