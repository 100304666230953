/* eslint-disable @typescript-eslint/no-unused-vars */
import { Switch } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import CONSTANTS from '../../../constants/env'

type Props = {
  children: ReactNode
}

export default function ExternalLayout({ children }: Props) {
  const [hidden, setHidden] = useState(false)

  const checkIfInIframe = () => window.self !== window.top

  const hiddenHeader = checkIfInIframe() || hidden
  return (
    <div>
      {CONSTANTS.NODE_ENV === 'development' ? (
        <div className="fixed right-2 top-2 z-50">
          <Switch checked={hidden} onChange={(e) => setHidden(e.target.checked)} />
        </div>
      ) : null}
      <header className={twMerge('fixed top-0 h-10 w-full', hiddenHeader ? 'hidden' : null)}>
        <nav className="flex items-center justify-center gap-5">
          <Link to="/external">HOME</Link>
          <Link to="/external/internationalization">Internationalization</Link>
          <Link to="/external/avatar-list">AvatarList</Link>
          <Link to="/external/global-avatar">globalAvatar</Link>
          <Link to="/external/ai-mascot">AIMascot</Link>
        </nav>
      </header>
      <main className={twMerge('flex min-h-screen justify-center', hiddenHeader ? null : 'pt-10')}>{children}</main>
    </div>
  )
}
